/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import route_42ea49ce from '/home/ergonode/pim/frontend/modules/@ergonode/activity-logs/src/config/routes.js';
import route_4c718ffc from '/home/ergonode/pim/frontend/modules/@ergonode/attributes/src/config/routes.js';
import route_451668b4 from '/home/ergonode/pim/frontend/modules/@ergonode/attribute-groups/src/config/routes.js';
import route_7235c601 from '/home/ergonode/pim/frontend/modules/@ergonode/authentication/src/config/routes.js';
import route_00efb85d from '/home/ergonode/pim/frontend/modules/@ergonode/categories/src/config/routes.js';
import route_d7d0db9a from '/home/ergonode/pim/frontend/modules/@ergonode/category-trees/src/config/routes.js';
import route_6f46d1fa from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/config/routes.js';
import route_0d641872 from '/home/ergonode/pim/frontend/modules/@ergonode/dashboard/src/config/routes.js';
import route_f455e22e from '/home/ergonode/pim/frontend/modules/@ergonode/channels/src/config/routes.js';
import route_efd76e12 from '/home/ergonode/pim/frontend/modules/@ergonode/media/src/config/routes.js';
import route_6f84b726 from '/home/ergonode/pim/frontend/modules/@ergonode/collections/src/config/routes.js';
import route_0ddb65d5 from '/home/ergonode/pim/frontend/modules/@ergonode/products/src/config/routes.js';
import route_07d30d8a from '/home/ergonode/pim/frontend/modules/@ergonode/product-statuses/src/config/routes.js';
import route_1433dbe0 from '/home/ergonode/pim/frontend/modules/@ergonode/product-templates/src/config/routes.js';
import route_26556d19 from '/home/ergonode/pim/frontend/modules/@ergonode/segments/src/config/routes.js';
import route_ad66a514 from '/home/ergonode/pim/frontend/modules/@ergonode/status-transitions/src/config/routes.js';
import route_3039c884 from '/home/ergonode/pim/frontend/modules/@ergonode/roles/src/config/routes.js';
import route_42abad5a from '/home/ergonode/pim/frontend/modules/@ergonode/users/src/config/routes.js';
import route_6cb631f4 from '/home/ergonode/pim/frontend/modules/@ergonode/import/src/config/routes.js';
import route_4c57134c from '/home/ergonode/pim/frontend/modules/@ergonode/workflow/src/config/routes.js';

export default {
	route_42ea49ce,
	route_4c718ffc,
	route_451668b4,
	route_7235c601,
	route_00efb85d,
	route_d7d0db9a,
	route_6f46d1fa,
	route_0d641872,
	route_f455e22e,
	route_efd76e12,
	route_6f84b726,
	route_0ddb65d5,
	route_07d30d8a,
	route_1433dbe0,
	route_26556d19,
	route_ad66a514,
	route_3039c884,
	route_42abad5a,
	route_6cb631f4,
	route_4c57134c,
};
