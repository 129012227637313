/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import middleware_90ebd4d4 from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/middleware/privilegeRoutingCheck.global.js';

export default (ctx) => {
	middleware_90ebd4d4(ctx);
};
