/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */

import deepmerge from 'deepmerge';
import extendsModules from '~/.nuxt/extends.modules';

export default ({ app }, inject) => {
    const extendMethods = {};
    const extendComponents = Object.values(extendsModules).reduce(
        (acc, current) => deepmerge(acc, current.extendComponents || {}),
        {},
    );

    Object.values(extendsModules).forEach((extendContent) => {
        if (extendContent.extendMethods) {
            Object.keys(extendContent.extendMethods).forEach((extendName) => {
                if (!extendMethods[extendName]) {
                    extendMethods[extendName] = [
                        extendContent.extendMethods[extendName],
                    ];
                } else {
                    extendMethods[extendName].push(
                        extendContent.extendMethods[extendName],
                    );
                }
            });
        }
    });

    inject('getExtendSlot', (key) => extendComponents[key] || {});
    inject('getExtendMethod', async (key, payload) => {
        if (extendMethods[key]) {
            const methods = await Promise.all(
                extendMethods[key].map((method) => method(payload)),
            );

            return methods;
        }
        return [];
    });
};
